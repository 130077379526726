import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

const AsciiDoubleSlit = () => {
  const [asciiFrame, setAsciiFrame] = useState("");
  const timeRef = useRef(0);
  const animationRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Memoize constants and configuration
  const config = useMemo(() => {
    const charWidth = 6;
    const charHeight = 12;
    const cols = Math.floor(window.innerWidth / charWidth);
    const rows = Math.floor(window.innerHeight / charHeight);
    const wavelength = 8.5;
    const waveSpeed = 0.75;
    const slitSeparation = rows * 0.4;
    const slitX = 0;
    const k = (2 * Math.PI) / wavelength;

    return {
      cols,
      rows,
      wavelength,
      waveSpeed,
      slitX,
      k,
      slits: [
        { x: slitX, y: Math.floor(rows / 2 - slitSeparation / 2) },
        { x: slitX, y: Math.floor(rows / 2 + slitSeparation / 2) },
      ],
    };
  }, []); // Empty deps array since these values don't change

  // Memoize ASCII characters array
  const asciiChars = useMemo(
    () => " .=+*#%@YYYRRRAAANNNEEEMMMUUULLL".split(""),
    []
  );

  // Pre-calculate static elements
  const staticElements = useMemo(() => {
    const { cols, rows, slitX, slits } = config;
    const staticMap = Array(rows)
      .fill()
      .map(() => Array(cols).fill(null));

    // Mark positions of slits and barrier
    for (let y = 0; y < rows; y++) {
      for (let x = 0; x < cols; x++) {
        if (x === slitX) {
          if (Math.abs(y - slits[0].y) < 2 || Math.abs(y - slits[1].y) < 2) {
            staticMap[y][x] = " ";
          } else {
            staticMap[y][x] = "║";
          }
        } else if (x < slitX) {
          staticMap[y][x] = " ";
        }
      }
    }
    return staticMap;
  }, [config]);

  // Pre-calculate distances for wave calculations
  const distanceMap = useMemo(() => {
    const { cols, rows, slits } = config;
    return slits.map((slit) =>
      Array(rows)
        .fill()
        .map((_, y) =>
          Array(cols)
            .fill()
            .map((_, x) => {
              const dx = x - slit.x;
              const dy = y - slit.y;
              return Math.sqrt(dx * dx + dy * dy);
            })
        )
    );
  }, [config]);

  // Optimized drawing function
  const draw = useCallback(() => {
    const { cols, rows, k, waveSpeed } = config;
    let asciiOutput = [];

    for (let y = 0; y < rows; y++) {
      let row = "";
      for (let x = 0; x < cols; x++) {
        // Use pre-calculated static elements if available
        if (staticElements[y][x] !== null) {
          row += staticElements[y][x];
          continue;
        }

        // Calculate interference pattern only for dynamic region
        let amplitude = 0;
        distanceMap.forEach((distances) => {
          const distance = distances[y][x];
          const phase = k * distance - waveSpeed * timeRef.current;
          amplitude += Math.sin(phase) / Math.max(1, Math.sqrt(distance));
        });

        const intensity = amplitude * amplitude;
        const normalizedIntensity = Math.min(1, Math.max(0, intensity * 3));
        const charIndex = Math.floor(
          normalizedIntensity * (asciiChars.length - 1)
        );
        row += asciiChars[charIndex];
      }
      asciiOutput.push(row);
    }

    setAsciiFrame(asciiOutput.join("\n"));
    timeRef.current += 0.1;
    animationRef.current = requestAnimationFrame(draw);
  }, [config, staticElements, distanceMap, asciiChars]);

  useEffect(() => {
    draw();
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [draw]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: isMobile ? "170vh" : "100%",
        background: "black",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      {!isMobile && (
        <div
          style={{
            width: "30%",
            height: "100vh",
            paddingRight: "4rem",
            paddingLeft: "4rem",
            color: "#fdf6e3",
            fontFamily: "monospace",
            fontSize: "20px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Lumenary is an applied AI lab building tools to make software
          development feel like whiteboarding.
          <br />
          <br />
          Our focus is to manage and augment the shared context of models and
          humans as they build together.
          <br />
          <br />
          Dan, John, Vivek, Ziray
          <br />
          {">"} contact@lumenary.com
        </div>
      )}
      <pre
        style={{
          width: isMobile ? "100%" : "70%",
          height: "100%",
          margin: 0,
          padding: 0,
          background: "black",
          color: "#fdf6e3",
          fontSize: "12px",
          lineHeight: "1",
          fontFamily: "monospace",
          whiteSpace: "pre",
          overflow: "hidden",
        }}
      >
        {asciiFrame}
      </pre>

      {isMobile && (
        <>
          <div
            style={{
              width: "fit",
              height: "fit",
              position: "absolute",
              bottom: "10vh",
              right: "5vh",
              padding: "1rem",
              color: "#fdf6e3",
              background: "black",
              fontFamily: "monospace",
              fontSize: "20px",
              overflowY: "auto",
            }}
          >
            Lumenary
          </div>

          <div
            style={{
              width: "100% - 5rem",
              height: "70vh",
              paddingBottom: "2rem",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              color: "#fdf6e3",
              background: "black",
              fontFamily: "monospace",
              overflowY: "auto",
              fontSize: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            Lumenary is an applied AI lab building tools to make software
            development feel like whiteboarding.
            <br />
            <br />
            Our focus is to manage and augment the shared context of models and
            humans as they build together.
            <br />
            <br />
            Dan, John, Vivek, Ziray
            <br />
            {">"} contact@lumenary.com
          </div>
        </>
      )}
    </div>
  );
};

export default AsciiDoubleSlit;
